import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import { default as React } from "react"
import OtherProducts from "../components/other-products"
import { colors } from "../theme"

const ThankYou = ({ data: { title, description, thankyouproducts } }) => {
  return (
    <Box>
      <Box pt={12} pb={24}>
        <Typography variant="h1" align="center" color="textPrimary">
          {title}
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary">
          {description}
        </Typography>
      </Box>
      {thankyouproducts && (
        <Box bgcolor={colors.grayLightest}>
          <Container maxWidth="lg">
            <OtherProducts {...thankyouproducts} />
          </Container>
        </Box>
      )}
    </Box>
  )
}

export default ThankYou
