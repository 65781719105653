import { graphql } from "gatsby"
import React from "react"
import Page from "../components/page"
import ThankYou from "../components/thank-you"

const ThankYouPage = ({
  data: { headerData, footerData, thankYouPageData },
}) => {
  return (
    <Page headerData={headerData} footerData={footerData}>
      <ThankYou data={thankYouPageData.frontmatter} />
    </Page>
  )
}

export default ThankYouPage

export const pageQuery = graphql`
  query ThankYouQuery {
    ...PageFragment
    thankYouPageData: markdownRemark(
      fileAbsolutePath: { regex: "/pages/thank-you.md/" }
    ) {
      frontmatter {
        title
        description
        thankyouproducts {
          title
          items {
            title
            description
            link
            thumbnail {
              source {
                childImageSharp {
                  fixed(width: 100) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  }
`
